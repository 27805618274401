import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { Table, toaster, Checkbox } from 'evergreen-ui'
import {
    Chart as ChartJS,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    LinearScale
} from 'chart.js'
import 'chartjs-adapter-date-fns'

import TopBar from '../../components/TopBar/TopBar'
import LeftMenu from '../../components/LeftMenu'
import MeterRow from '../../components/ListRows/MeterRows/MeterRow'
import LoadingRows from '../../components/ListRows/LoadingRows'
import PaginationButtons from '../../components/PaginationButtons'
import ActiveFilters from '../../components/activeFilters'
import Footer from '../../components/Footer'

import useApi from '../../hooks/useApi'

import { addMeters } from '../../redux/slices/meterSlice'
import { addUsers } from '../../redux/slices/userSlice'

import { fromGetUserTenantApi } from '../../utils/Adapters/UserApiAdapter'
import { fromMeterApi } from '../../utils/Adapters/MeterApiAdapter'
import getPathArguments from '../../utils/pathIDs'
import RowLimit from '../../components/RowLimit'
import NoResultsRow from '../../components/ListRows/NoResultsRow'

import PaginationInput from '../../components/PaginationInput'

ChartJS.register(LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale)

const TableRows = ({
    isLoaded,
    meters,
    onRowSelected,
    isSelectedEveryRow,
    isSelectedEveryRowIndeterminate
}) => {
    if (!isLoaded) {
        return <LoadingRows elements={meters} />
    }
    if (Object.values(meters).length === 0) {
        return <NoResultsRow />
    }

    return Object.entries(meters)
        .sort((a, b) => a[1].index - b[1].index)
        .map(([key, value]) => {
            return (
                <MeterRow
                    row={value}
                    key={key}
                    onRowSelected={onRowSelected}
                    isSelectedEveryRow={isSelectedEveryRow}
                    isSelectedEveryRowIndeterminate={isSelectedEveryRowIndeterminate}
                />
            )
        })
}

const MetersModerator = () => {
    const dispatch = useDispatch()

    const meters = useSelector((state) => state.meter.meters)
    const meterNextPage = useSelector((state) => state.meter.nextPage)
    const meterPreviousPage = useSelector((state) => state.meter.previousPage)
    const metersResultsCount = useSelector((state) => state.meter.count)
    const apiKey = useSelector((state) => state.profile.profile.apiKey)
    const rowLimit = useSelector((state) => state.profile.rowLimit)

    const [urlParams, setUrlParams] = useSearchParams()

    const [offset, setOffset] = useState(0)
    const [pageCount, setPageCount] = useState(1)

    // mass actions
    const [selectedRows, setSelectedRows] = useState([])
    const [isSelectedEveryRow, setIsSelectedEveryRow] = useState(false)
    const [isSelectedEveryRowIndeterminate, setIsSelectedEveryRowIndeterminate] = useState(false)
    const [massActionObject, setMassActionObject] = useState(null)

    // url params - filters
    const [userParam, setUserParam] = useState(null)
    const [meterParam, setMeterParam] = useState(null)
    const [typeParam, setTypeParam] = useState(null)
    const [startDateParam, setStartDateParam] = useState(null)
    const [endDateParam, setEndDateParam] = useState(null)
    const [searchParam, setSearchParam] = useState(null)

    // url params - sorting
    const [sortingParam, setSortingParam] = useState(null)

    const buildURL = () => {
        let baseURL = `/app/meters/moderator/meter/?limit=${rowLimit}&offset=${offset}`

        // filters
        if (userParam !== null) baseURL += `&tenant_member=${userParam}`
        if (meterParam !== null) baseURL += `&id__in=${meterParam}`
        if (typeParam !== null) baseURL += `&type=${typeParam}`
        if (startDateParam !== null && endDateParam !== null) {
            baseURL += `&created_at_after=${startDateParam}`
            baseURL += `&created_at_before=${endDateParam}`
        }
        if (searchParam !== null) baseURL += `&search=${searchParam}`

        // sorting
        if (sortingParam !== null) baseURL += `&ordering=${sortingParam}`

        return baseURL
    }

    const fetchMeters = useApi({
        url: buildURL(),
        method: 'GET',
        apiKey,
        fromApiAdapter: fromMeterApi,
        queryName: [
            'meters',
            offset,
            meterParam,
            userParam,
            typeParam,
            startDateParam,
            endDateParam,
            sortingParam,
            searchParam,
            rowLimit
        ],
        keepPreviousData: true,
        onSuccess: (data) => dispatch(addMeters(data))
    })

    const fetchUsersByID = useApi({
        url: `/app/tenants/moderator/tenant_member/?id__in=${getPathArguments(
            meters,
            'tenantMember'
        )}`,
        method: 'GET',
        apiKey,
        enabled: fetchMeters.isSuccess,
        fromApiAdapter: fromGetUserTenantApi,
        queryName: ['users', Object.keys(meters)],
        onSuccess: (data) => dispatch(addUsers(data))
    })

    const onPageClick = (page, direction) => {
        if (page !== null) {
            if (direction === 1) setPageCount((old) => old + 1)
            else setPageCount((old) => Math.max(old - 1, 0))
            const offsetPosition = page.indexOf('offset')
            if (offsetPosition !== -1) setOffset(page.substring(offsetPosition + 7, page.lengt))
            else setOffset(0)
        }
    }

    const handleRowSelectChanged = (pk) => {
        if (selectedRows.indexOf(pk) !== -1)
            setSelectedRows((current) => current.filter((elem) => elem !== pk))
        else setSelectedRows((current) => [...current, pk])
    }

    const handeSelectEveryRowClick = () => {
        if (isSelectedEveryRowIndeterminate) {
            setSelectedRows([])
            setIsSelectedEveryRowIndeterminate(false)
            return
        }

        if (isSelectedEveryRow) setSelectedRows([])
        else {
            const ids = Object.values(meters).map((meter) => meter.pk)
            setSelectedRows(ids)
        }
        setIsSelectedEveryRow((current) => !current)
    }

    const handleMassActionClick = (actionObject) => {
        if (selectedRows.length === 0) {
            toaster.notify('Nie wybrano żadnego wiersza')
            return
        }

        setMassActionObject(actionObject)
    }

    const handlePaginationInputonBlur = (e) => {
        setOffset(rowLimit * e - rowLimit)
        setPageCount(e)
    }

    // logika zaznaczania wierszy
    useEffect(() => {
        if (Object.values(selectedRows).length === 0) {
            setIsSelectedEveryRow(false)
            setIsSelectedEveryRowIndeterminate(false)
        } else if (selectedRows.length === Object.values(meters).length) {
            setIsSelectedEveryRow(true)
            setIsSelectedEveryRowIndeterminate(false)
        } else {
            setIsSelectedEveryRow(false)
            setIsSelectedEveryRowIndeterminate(true)
        }
    }, [selectedRows])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }

        setUserParam(null)
        setMeterParam(null)
        setTypeParam(null)
        setStartDateParam(null)
        setEndDateParam(null)
        setSortingParam(null)
        setSearchParam(null)

        // filters
        const userParamGET = parseInt(urlParams.get('user_id'), 10)
        const meterParamGET = parseInt(urlParams.get('meter_id'), 10)
        const typeParamGET = urlParams.get('meter_type') || null
        const createdAtParamGET = urlParams.get('created_at') || null
        const searchParamGET = urlParams.get('search') || null
        // sorting
        const sortingParamGET = urlParams.get('sorting') || null

        // filters
        if (!Number.isNaN(userParamGET)) setUserParam(userParamGET)
        else if (!Number.isNaN(meterParamGET)) setMeterParam(meterParamGET)

        if (typeParamGET !== null) setTypeParam(typeParamGET)
        if (createdAtParamGET !== null) {
            const endDate = new Date()
            let startDate = null
            setEndDateParam(endDate.toISOString().split('T')[0])

            // 1000 * 60 * 60 * 24 - stands for one day in ms
            switch (createdAtParamGET) {
                case 'last_24h': {
                    startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_week': {
                    startDate = new Date(endDate.getTime() - 7 * 1000 * 60 * 60 * 24)
                    break
                }
                case 'last_month': {
                    startDate = new Date(endDate.getTime() - 30 * 1000 * 60 * 60 * 24)
                    break
                }
                default:
                    break
            }

            setStartDateParam(startDate.toISOString().split('T')[0])
        }
        if (searchParamGET !== null) setSearchParam(searchParamGET)

        // sorting
        if (sortingParamGET !== null) {
            switch (sortingParamGET) {
                case 'created_at_asc':
                    setSortingParam('created_at')
                    break
                case 'created_at_desc':
                    setSortingParam('-created_at')
                    break
                default:
                    break
            }
        }
    }, [urlParams])

    useEffect(() => {
        if (pageCount > 0) {
            setPageCount(1)
            setOffset(0)
        }
    }, [rowLimit])

    return (
        <div className="main">
            <TopBar />
            <LeftMenu active="meters" />
            <div className="main_content">
                <div className="title">
                    <div className="title_name">Liczniki</div>
                    <ActiveFilters onMassActionSelect={handleMassActionClick} meterFilters />
                </div>
                <div className="container">
                    <div className="container_main">
                        <div className="container_cards">
                            <Table className="table">
                                <Table.Head className="header">
                                    <Table.TextHeaderCell
                                        flexBasis="40px"
                                        flexShrink={0}
                                        flexGrow={0}
                                    >
                                        <Checkbox
                                            checked={isSelectedEveryRow}
                                            indeterminate={isSelectedEveryRowIndeterminate}
                                            onChange={handeSelectEveryRowClick}
                                        />
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Nazwa obiorcy
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Typ licznika
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Numer licznika
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell className="table_title_col">
                                        Data utworzenia
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell
                                        className="table_title_col"
                                        flexBasis="180px"
                                        flexShrink={0}
                                        flexGrow={0}
                                        justifyContent="flex-end"
                                    >
                                        Działania
                                    </Table.TextHeaderCell>
                                    <Table.TextHeaderCell
                                        flexBasis="1px"
                                        flexShrink={0}
                                        flexGrow={0}
                                        paddingLeft={1}
                                        paddingRight={1}
                                    />
                                    <Table.TextHeaderCell
                                        flexBasis="50px"
                                        flexShrink={0}
                                        flexGrow={0}
                                        padding={0}
                                    />
                                </Table.Head>
                                <Table.Body>
                                    <TableRows
                                        isLoaded={fetchUsersByID.isSuccess}
                                        meters={meters}
                                        onRowSelected={handleRowSelectChanged}
                                        isSelectedEveryRow={isSelectedEveryRow}
                                        isSelectedEveryRowIndeterminate={
                                            isSelectedEveryRowIndeterminate
                                        }
                                    />
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="pagination_box">
                            <PaginationInput
                                pageCount={pageCount}
                                onBlur={handlePaginationInputonBlur}
                                resultCount={metersResultsCount}
                                apiLimit={rowLimit}
                            />
                            <RowLimit />
                        </div>
                        <PaginationButtons
                            pageCount={pageCount}
                            onPageClick={onPageClick}
                            prevPage={meterPreviousPage}
                            nextPage={meterNextPage}
                            resultCount={metersResultsCount}
                            apiLimit={rowLimit}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MetersModerator
