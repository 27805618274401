import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { BsSpeedometer, BsClockHistory, BsFillPersonFill } from 'react-icons/bs'
import { MdOutlineImage, MdOutlineImageNotSupported } from 'react-icons/md'
import Zoom from 'react-medium-image-zoom'
import { TiTickOutline, TiTick } from 'react-icons/ti'
import { FiX } from 'react-icons/fi'
import { Dialog, Pane, Table, Tooltip, Checkbox } from 'evergreen-ui'

import renderMeterIconType from '../../utils/renderMeterIconType'
import { renderState } from '../../utils/renderStateMeasurement'

import dateConvert from '../../utils/formatDate'

const MeasurementRow = ({
    measurement = {},
    meter = {},
    onConfirmMeasurement,
    onRowSelected,
    isSelectedEveryRow,
    isSelectedEveryRowIndeterminate
}) => {
    const user = useSelector((state) => state.user.users[meter.tenantMember])
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    const [isImageShown, setIsImageShown] = useState(false)
    const [imageUrl, setImageUrl] = useState()
    const [isSelected, setIsSelected] = useState(false)

    const isPrivate = meter.tenantMember === null || measurement.state === 'non_billed'

    const handleShowMeterImageClick = () => {
        setImageUrl(meter.image)
        setIsImageShown(true)
    }

    const handleShowMeasurementImageClick = () => {
        setImageUrl(measurement.image)
        setIsImageShown(true)
    }

    const handleSelectClick = (e) => {
        const newState = e.target.checked
        setIsSelected(newState)
        onRowSelected(measurement.id)
    }

    const verificationStatusIcon = () => {
        if (measurement.state === 'verified') {
            return (
                <Tooltip content="Odczyt zatwierdzony" showDelay={500}>
                    <button
                        type="button"
                        className="button"
                        onClick={() => onConfirmMeasurement(measurement)}
                    >
                        <TiTick size="18" color="#00b300" />
                    </button>
                </Tooltip>
            )
        }
        if (measurement.state === 'rejected') {
            return (
                <Tooltip content="Odczyt odrzucony" showDelay={500}>
                    <button
                        type="button"
                        className="button"
                        onClick={() => onConfirmMeasurement(measurement)}
                    >
                        <FiX size="18" color="#F84F31" />
                    </button>
                </Tooltip>
            )
        }
        return (
            <Tooltip content="Sprawdź dane odczytu" showDelay={500}>
                <button
                    type="button"
                    className="button"
                    onClick={() => onConfirmMeasurement(measurement)}
                >
                    <TiTickOutline size="18" />
                </button>
            </Tooltip>
        )
    }

    const renderImageButton = () => {
        return (
            <div className="renderImageButtonDiv">
                <button
                    type="button"
                    className="pictureButton"
                    onClick={handleShowMeterImageClick}
                    disabled={!meter.image}
                >
                    {meter.image ? (
                        <MdOutlineImage size="18" fill="green" />
                    ) : (
                        <MdOutlineImageNotSupported size="18" fill="orange" />
                    )}
                    {meter.serialNumber ? meter.serialNumber : 'Zdjęcie'}
                </button>
            </div>
        )
    }

    const renderMeasurementImageButton = () => {
        const renderValue = () => {
            const value = measurement.value.replace('.', ',')
            const { unit } = meter

            return `${value} ${unit || ''}`
        }

        return (
            <div className="renderImageButtonDiv">
                <button
                    type="button"
                    className="pictureButton"
                    onClick={handleShowMeasurementImageClick}
                    disabled={!measurement.image}
                >
                    {measurement.image ? (
                        <MdOutlineImage size="18" fill="green" />
                    ) : (
                        <MdOutlineImageNotSupported size="18" fill="orange" />
                    )}
                    {measurement.value ? renderValue() : 'Zdjęcie'}
                </button>
            </div>
        )
    }

    const renderUserName = () => {
        if (isPrivate) return '-'
        if (user?.isCompany) return user?.companyName
        return user?.nameSurname
    }

    const renderRowClassName = () => {
        if (isSelected) return 'row selected'
        if (isModerator && measurement.state === 'new') return 'row not_verified'
        if (!isModerator && measurement.state === 'rejected') return 'row not_verified'
        return 'row'
    }

    useEffect(() => {
        if (isSelectedEveryRow) setIsSelected(isSelectedEveryRow)
        else if (!isSelectedEveryRow && !isSelectedEveryRowIndeterminate) setIsSelected(false)
    }, [isSelectedEveryRow, isSelectedEveryRowIndeterminate])

    return (
        <Table.Row className={renderRowClassName()}>
            {isModerator && (
                <Table.TextCell flexBasis="40px" flexGrow={0} flexShrink={0}>
                    <Checkbox checked={isSelected} onChange={(e) => handleSelectClick(e)} />
                </Table.TextCell>
            )}
            <Table.TextCell>
                <Tooltip content="Przejdź do odbiorcy" showDelay={500}>
                    <Link to={`/users?user_id=${user?.pk}`}>{renderUserName()}</Link>
                </Tooltip>
            </Table.TextCell>
            <Table.TextCell isNumber>
                <span>{renderMeterIconType(meter, 16)}</span>
            </Table.TextCell>
            {isModerator ? (
                <Table.TextCell isNumber>{renderImageButton()}</Table.TextCell>
            ) : (
                <Table.TextCell>{meter.name}</Table.TextCell>
            )}
            <Table.TextCell>{renderMeasurementImageButton()}</Table.TextCell>
            <Table.TextCell>{dateConvert(measurement.createdAt)}</Table.TextCell>
            {!isModerator && (
                <Table.TextCell isNumber>
                    <span>{renderState(measurement.state, 16)}</span>
                </Table.TextCell>
            )}
            <Table.TextCell flexBasis={isModerator ? '170px' : '140px'} flexShrink={0} flexGrow={0}>
                <div className="button_container">
                    <Tooltip content="Filtruj po odbiorcy" showDelay={500}>
                        <Link to={`/readings?user_id=${meter.tenantMember}`}>
                            <button type="button" className="button">
                                <BsFillPersonFill size="18" />
                            </button>
                        </Link>
                    </Tooltip>
                    <Tooltip content="Przejdź do licznika" showDelay={500}>
                        <Link to={`/meters?meter_id=${meter.pk}`}>
                            <button type="button" className="button">
                                <BsSpeedometer size="18" />
                            </button>
                        </Link>
                    </Tooltip>
                    <Tooltip content="Pokaż historię odczytów" showDelay={500}>
                        <Link to={`/readings?meter_id=${meter.pk}`}>
                            <button type="button" className="button">
                                <BsClockHistory size="18" />
                            </button>
                        </Link>
                    </Tooltip>
                    {isModerator && verificationStatusIcon()}
                </div>
            </Table.TextCell>
            <Pane>
                <Dialog
                    isShown={isImageShown}
                    title="Zdjęcie licznika"
                    onCloseComplete={() => setIsImageShown(false)}
                    hasFooter={false}
                >
                    <Zoom>
                        <div className="dialog_image_container">
                            <img src={imageUrl} alt="meter" />
                        </div>
                    </Zoom>
                </Dialog>
            </Pane>
        </Table.Row>
    )
}

export default MeasurementRow
