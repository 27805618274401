import { useSelector } from 'react-redux'

import { BiHomeAlt, BiCalculator } from 'react-icons/bi'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { HiOutlineDocument } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { BsPeople } from 'react-icons/bs'
import { SiBookmeter, SiCodeforces } from 'react-icons/si'

import '../styles/components/menu.scss'
import { AiOutlineHistory } from 'react-icons/ai'

const LeftMenu = ({ active }) => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    return (
        <div className="menuComponent">
            <Link to="/home">
                <div className="element">
                    <button type="button" className={`${active === 'home' ? 'active' : ''}`}>
                        <BiHomeAlt size="25" />
                    </button>
                    <span>Strona Główna</span>
                </div>
            </Link>

            <Link to="/users">
                <div className="element">
                    <button type="button" className={`${active === 'users' ? 'active' : ''}`}>
                        <BsPeople size="25" />
                    </button>
                    <span>{isModerator ? 'Odbiorcy' : 'Wnioski'}</span>
                </div>
            </Link>

            <Link to="/meters">
                <div className="element">
                    <button type="button" className={`${active === 'meters' ? 'active' : ''}`}>
                        <BiCalculator size="25" />
                    </button>
                    <span>Liczniki</span>
                </div>
            </Link>

            <Link to="/readings">
                <div className="element">
                    <button
                        type="button"
                        className={`${active === 'measurements' ? 'active' : ''}`}
                    >
                        <SiBookmeter size="25" />
                    </button>
                    <span>Odczyty</span>
                </div>
            </Link>

            <Link to="/consumption">
                <div className="element">
                    <button type="button" className={`${active === 'consumption' ? 'active' : ''}`}>
                        <SiCodeforces size="25" />
                    </button>
                    <span>Zużycie</span>
                </div>
            </Link>

            <Link to="/documents">
                <div className="element">
                    <button type="button" className={`${active === 'messages' ? 'active' : ''}`}>
                        <HiOutlineDocument size="25" />
                    </button>
                    <span>Dokumenty</span>
                </div>
            </Link>

            {isModerator && (
                <Link to="/notifications">
                    <div className="element">
                        <button
                            type="button"
                            className={`${active === 'notifications' ? 'active' : ''}`}
                        >
                            <IoMdNotificationsOutline size="25" />
                        </button>
                        <span>Powiadomienia</span>
                    </div>
                </Link>
            )}

            <Link to="/history">
                <div className="element">
                    <button type="button" className={`${active === 'history' ? 'active' : ''}`}>
                        <AiOutlineHistory size="25" />
                    </button>
                    <span>Historia</span>
                </div>
            </Link>
        </div>
    )
}

export default LeftMenu
