import { useSelector } from 'react-redux'

import ConsumptionModerator from './Consumption.moderator.view'

const Consumption = () => {
    const isModerator = useSelector((state) => state.profile.profile.isModerator)

    return isModerator ? <ConsumptionModerator /> : null
}

export default Consumption
